@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .glass {
    background-image: linear-gradient(
      to bottom right,
      rgba(18, 18, 18, 0.835) 0%,
      rgba(37, 37, 37, 0.447) 50%,
      rgba(8, 8, 8, 0.34) 100%
    );
  }

  .glass-thick {
    background-image: linear-gradient(
      to bottom right,
      rgba(156, 156, 156, 0.1) 0%,
      rgba(122, 122, 122, 0.1) 50%,
      rgba(48, 48, 48, 0.09) 100%
    );
  }

  .glass-light {
    background-image: linear-gradient(
      to bottom,
      rgba(234, 233, 246, 0.16) 0%,
      rgba(87, 87, 87, 0.22) 100%
    );
  }

  .glass-button-primary {
    background-image: linear-gradient(
      to bottom right,
      rgba(65, 77, 255, 0.78) 0%,
      rgba(74, 177, 255, 0.75) 100%
    );
  }

  .glass-button-secondary {
    background-image: linear-gradient(
      to bottom right,
      rgba(255, 214, 31, 0.5) 0%,
      rgba(255, 226, 94, 0.4) 50%,
      rgba(255, 240, 75, 0.17) 100%
    );
  }

  .glass-button-light {
    background-image: linear-gradient(
      to bottom right,
      rgba(164, 164, 164, 0.27) 0%,
      rgba(222, 222, 222, 0.1) 100%
    );
  }

  .glass-button-transparent {
    background-image: linear-gradient(
      to bottom right,
      rgba(47, 47, 47, 0.1) 0%,
      rgba(79, 79, 79, 0.08) 100%
    );
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .row-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .col-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
}

@layer base {
  :root {
    --theme-light: 72, 19%, 95%;
    --theme-dark: 60, 19%, 93%;

    --background: 0 0% 96.1%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 80 8% 85%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 95%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --success: 142 76% 36%;
    --success-foreground: 210 40% 98%;

    --ring: 215 20.2% 0% / 0;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --chart-6: 217 80% 50%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  a,
  p,
  h1,
  h2,
  div {
    @apply font-medium;
  }

  h1,
  h2 {
    @apply font-normal;
  }
}

body {
  /* asdasd.. */
  pointer-events: all !important;
}

.shepherd-button {
  background-color: #2387f2;
}
